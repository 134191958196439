.App {
  text-align: center;
  padding-bottom: 24px;
}

.pizzas {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 48px;
}

.pizzas p {
  font-size: 20px;
  font-weight: 500;
}

.small-pizza-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.big-pizza-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.winning-pizza img {
  animation: App-logo-spin infinite 20s linear;
}

@media only screen and (max-width: 800px) {
  .pizzas {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    margin-top: 48px;
  }
}

